import React from 'react'

const FrontLoader = () => {
  return (
   <div className='front-loading-box'>
     <div class="loading-spinner"></div>
   </div>
  )
}

export default FrontLoader